html,body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 0;
    margin: 0;
}
body {
    position: relative;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}
h1, h2, h3 {
    font-weight: 600;
    padding: 20px 0 20px 0;
    margin: 0;
}
h1 {
    font-size: 24px;
    line-height: 24px;
}
h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
}
h3 {
    font-size: 16px;
    padding: 10px 0 10px 0;
}
h4, h5 {
    padding: 10px 0 10px 0;
    margin: 0;
}
h4 {
    font-size: 20px;
    border-bottom: 1px solid #ddd;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 18px;
    margin-bottom: 10px;
}
a, a:hover, a:focus {
    text-decoration: none;
    color: #5fbb47;
}
b {
    font-weight: bold;
}
img {
    border: 0;
}
i {
    color: inherit;
}
form {
    padding: 0;
    margin: 0;
}
textarea {
    color: #333;
}
#loading {
    display: none;
    background: url("/images/loading.svg") no-repeat center center #fff;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.85;
    position: fixed;
    outline: none !important;
    width: 100%;
    z-index: 999;
}
#loading.active {
    display: block;
}
.force-word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
.text-underline {
    text-decoration: underline;
}
.text-bold{
    font-weight: bold;
}
.text-light{
    font-weight: normal;
}
.datepicker th.datepicker-switch {
    text-decoration: underline;
}
@media (max-width: 767px) {
    .form-group .control {
        padding-right: 0;
    }
}
.w100 {
    width: 100%;
}
.w50 {
    width: 50%;
}
.w25 {
    width: 25%;
}
.w20 {
    width: 20%;
}
.w15 {
    width: 15%;
}
.w10 {
    width: 10%;
}
.nmg {
    margin: 0;
}
.nmgl {
    margin-left: 0;
}
.nmgr {
    margin-right: 0;
}
.nmgt {
    margin-top: 0;
}
.nmgb {
    margin-bottom: 0;
}
.mg {
    margin: 15px;
}
.mgl {
    margin-left: 15px;
}
.mgr {
    margin-right: 15px;
}
.mgt {
    margin-top: 15px;
}
.mgb {
    margin-bottom: 15px;
}
.mg-xs {
    margin: 2.5px;
}
.mg-sm {
    margin: 5px;
}
.mg-lg {
    margin: 30px;
}
.mgl-xs {
    margin-left: 2.5px;
}
.mgr-xs {
    margin-right: 2.5px;
}
.mgt-xs {
    margin-top: 2.5px;
}
.mgb-xs {
    margin-bottom: 2.5px;
}
.mgl-sm {
    margin-left: 5px;
}
.mgr-sm {
    margin-right: 5px;
}
.mgt-sm {
    margin-top: 5px;
}
.mgb-sm {
    margin-bottom: 5px;
}
.mg-lg {
    margin: 30px;
}
.mgl-lg {
    margin-left: 30px;
}
.mgr-lg {
    margin-right: 30px;
}
.mgt-lg {
    margin-top: 30px;
}
.mgb-lg {
    margin-bottom: 30px;
}
.mgt-10 {
    margin-top: 10px;
}
.mgr-10 {
    margin-right: 10px;
}
.mgb-10 {
    margin-bottom: 10px;
}
.mgl-10 {
    margin-left: 10px;
}
.mgt-20 {
    margin-top: 20px;
}
.mgr-20 {
    margin-right: 20px;
}
.mgb-20 {
    margin-bottom: 20px;
}
.mgl-20 {
    margin-left: 20px;
}
.npd {
    padding: 0;
}
.pd {
    padding: 15px;
}
.pd-xs {
    padding: 2.5px;
}
.pd-sm {
    padding: 5px;
}
.pd-lg {
    padding: 30px;
}
.npdl {
    padding-left: 0;
}
.npdr {
    padding-right: 0;
}
.npdt {
    padding-top: 0;
}
.npdb {
    padding-bottom: 0;
}
.pdl {
    padding-left: 15px;
}
.pdr {
    padding-right: 15px;
}
.pdt {
    padding-top: 15px;
}
.pdb {
    padding-bottom: 15px;
}
.pdl-xs {
    padding-left: 2.5px;
}
.pdr-xs {
    padding-right: 2.5px;
}
.pdt-xs {
    padding-top: 2.5px;
}
.pdb-xs {
    padding-bottom: 2.5px;
}
.pdl-sm {
    padding-left: 5px;
}
.pdr-sm {
    padding-right: 5px;
}
.pdt-sm {
    padding-top: 5px;
}
.pdb-sm {
    padding-bottom: 5px;
}
.pdl-lg {
    padding-left: 30px;
}
.pdr-lg {
    padding-right: 30px;
}
.pdt-lg {
    padding-top: 30px;
}
.pdb-lg {
    padding-bottom: 30px;
}
.pdt-10 {
    padding-top: 10px;
}
.pdr-10 {
    padding-right: 10px;
}
.pdb-10 {
    padding-bottom: 10px;
}
.pdl-10 {
    padding-left: 10px;
}
.pdt-20 {
    padding-top: 20px;
}
.pdr-20 {
    padding-right: 20px;
}
.pdb-20 {
    padding-bottom: 20px;
}
.pdl-20 {
    padding-left: 20px;
}
.btn-primary,
.btn-secondary,
.btn-success,
.btn-default,
.btn-danger{
    background-color: #ffffff;
}
.btn-primary.disabled:hover,
.btn-primary:disabled:hover,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover,
.btn-success.disabled:hover,
.btn-success:disabled:hover,
.btn-default.disabled:hover,
.btn-default:disabled:hover,
.btn-danger.disabled:hover,
.btn-danger:disabled:hover
{
    background-color: #f5f5f5;
}
.color-grey{
    color: #949ea7;
}
.bg-green{
    background-color: #5fbb46;
}
.bg-red{
    background-color: #ff0000;
}
.bg-cyan{
    background-color: #00aeef;
}
.bg-orange{
    background-color: #f26522;
}
.container {
    width: auto;
    margin: 0 15px;
}

@media (max-width: 767px) {
    .block-xs {
        display: block;
        width: 100%;
    }
}