html {
	height: 100% !important;
}
body {
	min-height: 100% !important;
	background-color: #404F5D;
	overflow: auto !important; /* Need for modal dialog */
	padding-right: 0 !important;
}
body.green {
	background-color: #5EBB46;
}
body.dark {
	background-color: #404F5D;
}
body.skyline {
	background-image: url('/images/skyline.png');
	background-repeat: no-repeat;
	background-position: center bottom;
}
h1 {
	text-transform: uppercase;
}
.static-field {
	padding: 0 0 7px 0;
}
.bordered .static-field {
	border-bottom: 1px solid #bbb;
	min-height: 36px;
	padding: 7px 0;
	margin: 0 0 10px 0;
}
#root {
	min-height: 100%;
}
.splash-screen {
	width: 100%;
	max-width: 400px;
	text-align: center;
	transition: all 0.75s linear;
	opacity: 1;
	padding: 60px 15px 30px 15px;
	margin: 0 auto;
}
.splash-screen.collapsed {
	opacity: 0;
}
.splash-screen .logo {
	width: 200px;
}
.splash-screen .desc {
	color: #fff;
	text-align: center;
	padding: 30px 0 0 0;
}
#login-screen {
	transition: all 0.3s linear;
	padding-top: 30px;
	margin-top: 0;
}
#login-screen.collapsed {
	margin-top: -600px;
}
.generic-panel-container {
	width: 100%;
	max-width: 720px;
	margin: 0 auto 30px auto;
}
.login-container {
	width: 100%;
	max-width: 400px;
	margin: 0 auto 30px auto;
}
.generic-panel-header,
.login-header {
	text-align: center;
	color: #fff;
	padding: 30px 0 0 0;
}
.generic-panel-logo,
.login-logo {
	text-align: center;
	padding: 0 0 15px 0;
}
.generic-panel-logo img,
.login-logo img {
	width: 200px;
	max-width: 100%;
}
.generic-panel-logo a,
.login-logo a {
	display: block;
}
.btn .fa {
	margin-right: 7px;
}
.btn .fa.right {
	margin-left: 7px;
}
.btn .fa.nsp {
	margin-left: 0;
	margin-right: 0;
}
.loader-button img {
	display: inline-block;
	width: 20px;
	margin: 0 7px 0 0;
}
.main .container {
	min-height: 400px;
	color: #fff;
}
.status-panel .container {
	color: #fff;
	padding-left: 60px;
}
.header {
	height: 80px;
	background: #5EBB46;
}
.header .logo {
	display: inline-block;
	margin: 17px 0;
}
.header .logo img {
	height: 45px;
}
.header .logout-btn {
	display: block;
	float: right;
	height: 44px;
	line-height: 40px;
	background: transparent;
	color: #fff;
	border: 2px solid #fff;
	border-radius: 5px;
	padding: 0 30px;
	margin: 18px 0 0 15px;
}
#root .wrapper {
	padding-bottom: 70px;
}
.wrapper .frame {
	position: relative;
	transition: all 0.25s linear;
	padding: 0 0 0 240px;
}
.wrapper.sidebar-collapsed .frame {
	padding-left: 65px;
}
.status-panel {
	background: #13181c;
	color: #fff;
	padding: 5px 0;
}
.status-panel span {
	display: inline-block;
	line-height: 35px;
	margin: 0 35px 0 0;
}
.status-panel span i {
	margin: 0 7px 0 0;
}
.status-panel a {
	color: #fff;
}
.wrapper .sidebar {
	position: absolute;
	top: 80px;
	left: 0;
	z-index: 89;
	background: #13181c;
	min-height: calc(100% - 80px);
}
.sidebar #sidebar-toggle {
	cursor: pointer;
	position: absolute;
	right: -40px;
	top: 10px;
}
.wrapper.sidebar-collapsed.sidebar-float #sidebar-toggle {
	display: none;
}
.sidebar .bar {
	width: 240px;
	min-height: calc(100vh - 80px);
	overflow-y: hidden;
	transition: all 0.25s linear;
	padding: 0;
	margin: 0;
}
.sidebar ul.menu {
	width: 100%;
	padding: 0 0 175px 0;
	margin: 0;
}
.wrapper.sidebar-collapsed .sidebar .bar {
	width: 65px;
}
.wrapper.sidebar-collapsed.sidebar-float .sidebar .bar {
	width: 240px;
}
.sidebar ul.menu li {
	list-style: none;
	display: block;
	position: relative;
	padding: 0;
	margin: 0;
}
.sidebar ul.menu li a {
	display: block;
	position: relative;
	color: #fff;
	border-radius: 0;
	min-height: 45px;
	padding: 0 10px;
	margin: 0 10px;
}
.sidebar ul.menu li a.arrow {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	width: 40px;
	text-align: center;
	height: 45px;
	line-height: 45px;
	padding: 0;
}
.sidebar ul.menu li a span {
	color: #fff;
}
.sidebar ul.menu li a.active {
	background: #28333D;
	color: #fff;
	border-radius: 4px;
}
.sidebar ul.menu li li a.active {
	background: transparent;
	border-radius: 0;
	font-weight: 600;
}
.sidebar ul.menu li a.active-parent {
	background: #28333D;
	color: #fff;
}
.sidebar ul.menu li a .icon {
	display: block;
	position: absolute;
	top: 0;
	left: 10px;
}
.sidebar ul.menu li a .icon img {
	width: 25px;
	height: 25px;
	margin: 10px 0;
}
.sidebar ul.menu li a .short {
	display: block;
	position: absolute;
	top: 0;
	left: 10px;
	width: 25px;
	height: 25px;
	line-height: 23px;
	font-size: 12px;
	text-align: center;
	border: 1px solid #fff;
	border-radius: 50%;
	margin: 10px 0;
}
.sidebar ul.menu li a .desc {
	display: block;
	height: 45px;
	line-height: 45px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 0 0 40px;
}
.sidebar ul.menu li li a.active .short,
.sidebar ul.menu li li a.active .desc {
	font-weight: 600;
}
.sidebar ul.menu li li a.active .short  {
	background: #ccc;
	color: #000;
}
.wrapper.sidebar-collapsed .sidebar ul.menu li .arrow, 
.wrapper.sidebar-collapsed .sidebar ul.menu li a .desc {
	display: none;
}
.wrapper.sidebar-collapsed.sidebar-float .sidebar ul.menu li .arrow, 
.wrapper.sidebar-collapsed.sidebar-float .sidebar ul.menu li a .desc {
	display: block;
}
.sidebar ul.menu ul {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s linear;
	padding: 0;
	margin: 0;
}
.sidebar ul.menu ul.open {
	max-height: 200px;
}
.sidebar ul.menu ul li:last-child {
	margin-bottom: 10px;
}
.sidebar ul.links {
	position: absolute;
	left: 0;
	bottom: 10px;
	overflow: hidden;
	transition: width 0.3s linear;
	padding: 0;
	margin: 0;
}
.sidebar-collapsed .sidebar ul.links {
	display: none;
}
.sidebar-collapsed.sidebar-float .sidebar ul.links {
	display: block;
}
.sidebar ul.links li {
	display: block;
	list-style: none;
	color: #fff;
	font-size: 12px;
	line-height: 24px;
	padding: 4px 20px;
	margin: 0;
}
.sidebar ul.links li a {
	font-size: 12px;
	line-height: 24px;
	color: #fff;
}
.footer {
	color: #fff;
	line-height: 20px;
	text-align: center;
	padding: 0;
	margin: 20px 0 20px 0;
}
.footer ul {
	text-align: center;
	padding: 0;
	margin: 0;
}
.footer ul li {
	display: inline-block;
	list-style: none;
	color: #fff;
	font-size: 11px;
	line-height: 20px;
	padding: 0 7.5px;
	margin: 0;
}
.footer ul li a {
	font-size: 12px;
	line-height: 20px;
	color: #fff;
}
.wrapper.sidebar-collapsed .footer {
	padding-left: 65px;
}
@media (max-width: 720px) {
	.header .logout-btn {
		display: none;
		/* margin-right: 55px; */
	}
	.wrapper .frame {
		padding-left: 0;
	}
	.wrapper.sidebar-collapsed .frame {
		padding-left: 0;
	}
	.wrapper.sidebar-collapsed.sidebar-float .frame {
		padding-left: 0;
	}
	.status-panel .container {
		padding-left: 15px;
	}
	.wrapper .sidebar {
		left: auto;
		right: 0;
	}
	.wrapper .sidebar .bar {
		width: 0;
		transition: all 0.3s linear;
	}
	.wrapper.sidebar-collapsed .sidebar .bar,
	.wrapper.sidebar-collapsed.sidebar-float .sidebar .bar  {
		width: 240px;
	}
	.wrapper.sidebar-collapsed .sidebar ul.menu li .arrow, 
	.wrapper.sidebar-collapsed .sidebar ul.menu li a .desc {
		display: block;
	}
	.sidebar #sidebar-toggle {
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: -62px;
	}
	.wrapper.sidebar-collapsed.sidebar-float #sidebar-toggle {
		display: block;
	}
	.wrapper .sidebar #sidebar-toggle img {
		height: 40px;
	}
	.wrapper .footer,
	.wrapper.sidebar-collapsed .footer {
		padding-left: 0;
	}
}

.mico {
	height: 24px;
}
.card {
	display: block;
	position: relative;
	border-radius: 6px;
	background: #fff;
	transition: all 0.25s ease-in-out;
}
.card.dasboard {
	background: #a0a7ae;
	color: #fff;
	margin: 20px 0 50px 0;
}
.card.dasboard:hover {
	background: #29333c;
}
.card .card-title {
	font-size: 18px;
	padding: 10px 20px;
}
.card.wic .card-title {
	color: #495057;
	min-height: 40px;
	margin: 20px 0 0 75px;
}
.card .card-body {
	font-size: 14px;
	color: #495057;
	padding: 30px 20px 30px 20px;
}
.card .card-body.nopdt {
	padding: 0 20px 30px 20px;
}
.card.dasboard div {
	color: #fff;
}
.card.dasboard .card-body {
	padding: 10px 15px 10px 90px;
}
.card h3 {
	font-size: 16px;
	padding: 0 0 5px 0;
}
.card small {
	font-size: 11px;
}
.card .icon {
	position: absolute;
	background: #29333c;
	text-align: center;
	width: 60px;
	height: 60px;
	border-radius: 4px;
	transition: all 0.25s ease-in-out;
	margin: -20px 0 0 20px;
}

.card .icon-no-margin
{
	margin: 0;
}
.card.dasboard:hover .icon {
	background: #5fbb47;
}
.card .icon img {
	width: 30px;
	height: 30px;
	margin: 15px 0;
}
.card .card-footer {
	font-size: 13px;
	line-height: 13px;
	color: #fff;
	border-top: 1px solid #444;
	padding: 10px 0 15px 0;
	margin: 30px 15px 0 15px;
}
.card .dash-item-title {
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	min-height: 40px;
	text-align: right;
	padding: 0 10px 0 0;
	margin: 35px 0 20px 0;
}
.card .dash-item-title.nomgb {
	margin: 35px 0 0 0;
}
.card .dash-line {
	padding: 0 15px 15px 15px;
}
.disabled-view-only-white .MuiFormLabel-root.Mui-disabled {
	color: #bdbdbd;
}
.disabled-view-only-white .MuiInput-input.Mui-disabled {
	color: #fff;
}
.disabled-view-only-white .MuiInput-underline.Mui-disabled:before{
	border-bottom: 1px solid #fff;
}
.disabled-view-only-black .MuiFormLabel-root.Mui-disabled {
	color: #9e9e9e;
}
.disabled-view-only-black .MuiInput-input.Mui-disabled {
	color: #000000;
}
.disabled-view-only-black .MuiInput-underline.Mui-disabled:before{
	border-bottom: 1px solid #000000;
}
.disabled-view-only-black .schedule-icon{
	opacity: .4;
}
/* .amount-textbox .MuiInputBase-root.Mui-disabled:before{
	content: "\0024";
    color: #000000;
    padding-bottom: 6px;
}
.amount-textbox input.Mui-disabled{
	margin-left: 10px;
} */

.MuiInputAdornment-root .MuiTypography-root{
	font-size: 16px;
}
/* Chrome, Safari, Edge, Opera */
.no-spinner input::-webkit-outer-spin-button,
.no-spinner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spinner input[type=number] {
  -moz-appearance: textfield;
}
/* ISSUE section */
.issue-list{
	margin-bottom: 15px;
}
.issue-item{
	position: relative;
	font-size: 14px;
	border-radius: 15px;
	background-color: #29333c;
	color: #fff;
	padding: 15px 0;
	margin-bottom: 15px;
}
.issue-item.pdh{
	padding: 15px 15px;
}
.issue-item.active{
	background: #14181C;
}
.issue-item a{
	color: #fff;
}
.issue-item a.green{
	color: #5fbb47;
}
.issue-item .data-label {
	font-size: 14px;
	line-height: 14px;
	color: #949ea7;
	font-weight: 600;
	padding-bottom: 7px;
}
.issue-item .field.cost .data-label{
	word-break: break-word;
}
.issue-item .ref{
	float: left;
	margin: 0 0 0 15px;
}
.issue-item .issue-img{
	width: 100px;
	height: 100px;
	background: #fff;
    border: #ffffff 2px solid;
    border-radius: 8px;
	object-fit:cover;
}
.issue-item .issue-img.proc{
	padding: 15%;
	opacity: .7;
}
.issue-item .code{
	font-size: 16px;
	line-height: 20px;
	font-weight: 600;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0 0 7px 0;
}
.issue-item .info{
	width: calc(100% - 120px);
	float: right;
	padding: 0 15px;
}
.issue-item .desc{
	padding: 20px 15px 0 15px;
}
.issue-item .menu-icon{
    position: absolute;
    right: 5px;
	top: 10px;
	z-index: 81;
}
.issue-item .menu-icon img{
	height: 35px;
}
.issue-item .context-menu{
    position: absolute;
    right: 0;
	top: 0;
	max-height: 0;
	overflow: hidden;
	z-index: 80;
	transition: max-height 0.3s linear;
	padding: 0;
	margin: 0;
}
.issue-item .context-menu.open{
	max-height: 280px;
}
.issue-item .context-menu ul{
	background: #14181C;
	border: 2px solid #fff;
	border-radius: 12px;
	padding: 40px 15px 15px 15px;
	margin: 0;
}
.issue-item .context-menu li{
	border-top: 1px solid #fff;
	list-style: none;
	min-width: 140px;
	padding: 7px 0;
	margin: 0;
}
.issue-item .context-menu li:first-child{
	border-top: 0;
}
.issue-item .context-menu li a{
	display: block;
	line-height: 28px;
	color: #fff;
	padding: 0 30px 0 0;
	margin: 0;
}
.issue-item .issue-title{
	display: block;
	font-size: 16px;
    line-height: 20px;
	font-weight: 500;
	overflow-x: hidden;
	padding: 0 30px 5px 0;
}
.issue-item .issue-address{
	font-size: 14px;
    line-height: 24px;
	padding: 0;
}
.issue-item .issue-address img{
	display: inline-block;
	height: 24px;
	margin: 0 5px 0 -5px;
}
.issue-section-wrap {
	padding: 30px 15px 0 15px;
}
.create-issue-container {
    width: 100%;
	max-width: 600px;
	padding: 30px 0 30px 0;
    margin: 0 auto 0 auto;
}
.profile-container {
    width: 100%;
	max-width: 720px;
	padding: 30px 0 30px 0;
    margin: 0 auto 0 auto;
}
.media-files {
	margin: 5px -7.5px 0 -7.5px;
}
.media-files .preview-img {
	position: relative;
	padding: 0 7.5px 15px 7.5px;
}
.media-files .preview-img a.photo {
	position: relative;
	display: block;
	padding-top: 100%;
}
.media-files .preview-img a.photo.video,
.media-files .preview-img a.photo.doc {
	background: #404F5D;
}
.media-files .preview-img a.photo img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	background: #fff;
    border: #fff 2px solid;
	border-radius: 8px;
	z-index: 87;
}
.media-files .preview-img a.photo.proc img {
	padding: 15%;
	opacity: .7;
}
.media-files .preview-img a.photo.video img {
	padding: 15%;
	background: transparent;
}
.media-files .preview-img a.delete {
	display: none;
	position: absolute;
	top: 6px;
	right: 13px;
	z-index: 88;
	background: #f44336;
	border-radius: 5px;
	opacity: .9;
	line-height: 18px;
	padding: 2px;
}
.media-files .preview-img a.medium {
	display: none;
	position: absolute;
	bottom: 21px;
	right: 13px;
	z-index: 88;
	background: #687786;
	border-radius: 5px;
	opacity: .9;
	line-height: 18px;
	padding: 2px;
}
.media-files .preview-img a.medium.active {
	display: block;
	opacity: 1;
	background: #5fbb47;
}
.media-files .preview-img:hover a.delete,
.media-files .preview-img:hover a.medium {
	display: block;
}
.media-files .preview-img a.delete img,
.media-files .preview-img a.medium img {
	width: 24px;
}

.issue-maker,
.issue-status-date,
.issue-status-text{
	font-size: 14px;
	line-height: 1.5;
}
.issue-maker.small,
.issue-status-date.small,
.issue-status-text.small{
	font-size: 12px;
	line-height: 16px;
}
.issue-status-text{
	line-height: 15px;
}
.issue-item .map {
	position: relative;
	height: 300px;
}
.prop-issues-wrap,
.prop-map-wrap {
	padding: 30px 15px 0 15px;
}
.priority-color{
	height: 24px;
    width: 24px;
	border-radius: 50%;
	position: absolute;
    top: 15px;
    right: 15px;
	background: #949ea7;
}
.priority-color.urgent {
	background: #F34436;
}
.priority-color.high {
	background: #FF9700;
}
.priority-color.medium {
	background: #5EBB47;
}
.priority-color.low {
	background: #08A9F4;
}
.timeline-item,
.issue-note-item{
	position: relative;
	border-left: #5fbb46 3px solid;
	padding: 0 0 30px 15px;
	margin: 0 0 0 2px;
}
.timeline-item:last-child,
.issue-note-item:last-child{
	border-left : transparent 3px solid;
}
.timeline-item .status{
	position: relative;
}
.timeline-item .status .issue-status-interval{
	position: absolute;
	left: 50%;
	top: 0;
	line-height: 16px;
}

.timeline-item .status,
.issue-note-item .status{
	float: left;
	width: 100%;
    margin-top: -5px;
}
.issue-timeline-dot{
    position: absolute;
    top: -1px;
    left: -6px;
    height: 9px;
}
.issue-status-label.lgap,
.issue-status-text.lgap {
    margin-left: 32px;
}
.issue-feedback-wrap{
	background: #fff;
	color: #333;
	border-radius: 5px;
	padding: 10px 10px;
}
.issue-combo-wrap{
	background: #fff;
	border-radius: 5px;
	padding: 1.5px 8px 1.5px 10px;
}
.issue-control-wrap{
	background: #fff;
	border-radius: 5px;
	padding: 5px 8px;
}
.issue-control-wrap textarea{
	border: 0;
	color: #333;
	width: 100%;
}
.issue-combo-wrap .MuiInput-underline:before {
    border-bottom: 0;
}
.prop-issue-item {
	position: relative;
	border-top: 1px solid #fff;
	padding: 12px 0;
}
.prop-issue-item:first-child {
	border-top: 0;
}
.issue-item .prop-issue-item .info {
	float: left;
	position: relative;
	width: 125px;
	padding: 0;
}
.prop-issue-item .priority-color {
	right: 20px;
	top: 0;
	width: 21px;
	height: 21px;
}
.prop-issue-item .priority {
	text-transform: uppercase;
}
.prop-issue-item .status {
	font-size: 12px;
	line-height: 12px;
	padding: 5px 0 0 0;
}
.prop-issue-item .subject {
	float: left;
	width: calc(100% - 160px);
}
.prop-issue-item .actions {
	position: absolute;
	top: 12px;
	right: 0;
}
.prop-issues-wrap .view-issues {
	line-height: 24px;
}
.prop-doc-item {
	position: relative;
	border-bottom: 1px solid #fff;
	padding: 15px 0;
}
/* .prop-doc-item:first-child {
	border-top: 0;
} */
.prop-doc-item .doc-name {
	color: #949ea7;
	font-weight: 600;
}
.prop-doc-item .doc-act {
	position: absolute;
	top: 11px;
	right: 0;
	opacity: .5;
}

.issue-plan-attachment .prop-doc-item{
	border-bottom: none;
	padding: 8px 0;
}
.issue-plan-attachment .prop-doc-item .doc-name{
	color: #fff;
	
}
.control-rpad {
	margin-right: 140px;
}
.supplier-quotes-container{
	position: relative;
}
.actor-notify-check{
	position: absolute;
    right: 0;
    margin: 0;
    top: 19px;
}
.select-supplier-check{
    margin: 0 auto;
}
.actor-notify-check .MuiButtonBase-root{
	padding: 0;
}
.select-supplier-check input[type=checkbox]+svg{
	width: 30px;
    height: 30px;
}
.actor-notify-check input[type=checkbox]+svg{
	width: 30px;
    height: 30px;
}
/* @media (min-width: 992px) {
	.issue-title{
		max-width: calc(100% - 40px);
	}
	.issue-status-img{
		height: 30px;
		margin: 0 0 0 0;
	}
	.timeline-item .issue-status-img{
		margin: 0 0 0 0;
	}
	.issue-note-item .issue-status-img{
		margin: 5px 0 0 0;
	}
	.issue-status-label{
		margin-top: 5px;
		margin-left: 35px;
	}
	.timeline-item .issue-status-label{
		margin-top: 5px;
	}
	.issue-status-text{
		margin-left: 35px;
	}
} */
@media (max-width: 991px) {
	.issue-item .ref{
		width: auto;
		float: none;
		padding: 0 15px;
		margin: 0 0 15px 0;
	}
	.issue-item .info{
		width: 100%;
		float: none;
	}
	.issue-item .issue-title{
		display: block;
	}
	.issue-item .desc{
		width: auto;
		float: none;
		padding: 0 15px;
	}
	.issue-item .issue-img{
		max-width: 70px;
		max-height: 70px;
		float: right;
		margin-top: 15px;
	}
	.btn-issue-report{
		padding-left: 8px;
		padding-right: 8px;
	}
	.issue-item .status,
	.issue-item .priority{
		margin-top: 15px;
	}
	.issue-item .prop-issue-item .status,
	.issue-item .prop-issue-item .priority{
		margin-top: 0;
	}
}
.page-title {
	padding: 20px 0 20px 0;
}
.page-title h1 {
	font-size: 24px;
	line-height: 42px;
	text-transform: uppercase;
	padding: 0;
	margin: 0;
}
.section-title {
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	color: #949ea7;
	padding: 0 0 20px 0;
}
.section-title .icon {
	float: right;
	height: 24px;
	opacity: .5;
}
.action-btn {
	display: inline-block;
	color: #5EBB46;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	padding: 0;
	margin: 0;
}
.action-btn:hover,
.action-btn:visited {
	color: #5EBB46;
}
.action-btn.white {
	color: #fff;
}
.action-btn.white:hover,
.action-btn.white:visited {
	color: #fff;
}
.action-btn .icon {
	float: right;
	height: 24px;
	opacity: .5;
	margin: 0 0 0 15px;
}
.action-btn.white .icon {
	opacity: 1;
}
.action-btn.lg .icon {
	height: 35px;
	margin-top: 5px;
}

.steps {
	float: right;
	height: 36px;
	padding: 0;
	margin: 0;
}
.steps.nofloat {
	float: none;
}
.steps li {
	position: relative;
	float: left;
	list-style: none;
	padding: 0;
	margin: 0;
}
.steps li.step {
	list-style: none;
	padding: 0;
	margin: 0;
}
.steps li.step .num {
	display: block;
	width: 36px;
	height: 36px;
	line-height: 30px;
	text-align: center;
	color: #13181c;
	background: #fff;
	border: 3px solid #13181c;
	border-radius: 50%;
}
.steps li.step .num.visited {
	background: #5EBB46;
	color: #fff;
}
.steps li.step.active .num {
	background: #13181c;
	color: #fff;
}
.steps li .line {
	display: block;
	background:#13181c;
	width: 36px;
	height: 3px;
	padding: 0;
	margin: 16px 0 0 0;
}

.tabs-wrap{
	border-bottom: 2px solid #5EBB46;
	margin: 0 0 25px 0;
}
.tabs {
	padding: 0;
	margin: 0 -5px;
}
.tabs li {
	float: left;
	width: 50%;
	list-style: none;
	padding: 0 5px;
	margin: 0;
}
.tabs.c3 li {
	width: 33.33%;
}
.tabs li a {
	display: block;
	line-height: 50px;
	background: #29333c;
	border-radius: 5px 5px 0 0;
	color: #fff;
	font-size: 16px;
	padding: 0 15px;
}
.tabs li.active a {
	background: #14181C;
}
.tabs li a img {
	float: right;
	width: 30px;
	margin: 10px 0 0 0;
}
.tab-view {
	display: none;
}
.tab-view.active {
	display: block;
}
.media-name-container .MuiInput-underline.Mui-disabled:before{
	border-bottom-style: solid;
}
.media-name-container .MuiFormHelperText-root{
	color: #f44336;
}

.modal-popup {
	width: 720px;
	max-width: 100%;
	z-index: 90;
}
.modal-popup.sm {
	width: 540px;
}
.modal-popup.lg {
	width: 960px;
}
.modal-popup.xl {
	width: 1200px;
}
.modal-popup .data-label {
	font-size: 14px;
	line-height: 14px;
	color: #666;
	font-weight: 600;
	padding-bottom: 7px;
}
.fu-wrap {
	position: relative;
	display: block;
	overflow: hidden;
}
.fu-wrap.inline {
	min-width: 160px;
	display: inline-block;
}
.fu-wrap span {
	display: block;
	height: 40px;
	line-height: 40px;
	background: #eee;
	color: #333;
	text-align: center;
	padding: 0 20px;
}
.fu-wrap input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
}
.issue-media-preview {
	padding: 0 0 15px 0;
}
.issue-media-preview .preview-item {
	position: relative;
	background: #f6f6f6;
	padding: 5px;
	margin: 0 0 10px 0;
}
.issue-media-preview .preview-item:hover {
	background: #f0f0f0;
}
.issue-media-preview .preview-item .photo {
	float: left;
	height: 40px;
}
.issue-media-preview .preview-item .photo.video,
.issue-media-preview .preview-item .photo.doc {
	background: #949ea7;
}
.issue-media-preview .preview-item .caption {
	float: right;
	text-align: right;
	line-height: 40px;
	padding: 0 50px 0 15px;
	max-width: calc(100% - 80px);
}
.issue-media-preview .preview-item .delete {
	display: none;
	position: absolute;
	right: 5px;
	top: 10px;
	background: #f44336;
	padding: 3px;
}
.issue-media-preview .preview-item:hover .delete {
	display: block;
}
.issue-media-preview .preview-item .delete img {
	height: 24px;
}
.album {
	position: relative;
}
.album-photos {
	position: relative;
}
.album-photos img {
	display: none;
	width: 100%;
}
.album-photos img.active {
	display: block;
}
.album-photos video {
	display: none;
	width: 100%;
}
.album-photos video.active {
	display: block;
}
.album .prev,
.album .next {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 60px;
	z-index: 98;
}
.album .prev.video,
.album .next.video {
	top: 50%;
	height: auto;
}
.album .next {
	left: auto;
	right: 0;
}
.album:hover .prev,
.album:hover .next {
	display: block;
	opacity: .5;
}
.album .prev:hover,
.album .next:hover {
	background: #333;
}
.album .prev span,
.album .next span {
	position: absolute;
	top: 50%;
	left: 0;
	width: 60px;
	border-radius: 50%;
	text-align: center;
	overflow: hidden;
	background: #333;
	z-index: 99;
	opacity: .8;
	padding: 5px 0 5px 0;
	margin-top: -30px;
}
.album .next span {
	left: auto;
	right: 0;
}
.album .prev img,
.album .next img {
	width: 50px;
	margin: 0 -5px 0 -5px;
}
.holding-panel {
    width: 100%;
	max-width: 720px;
	padding: 30px 0 30px 0;
    margin: 0 auto 0 auto;
}
.holding-panel .holding-icon {
    width: 100%;
}
.holding-panel .holding-desc {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	padding: 0 0 30px 0;
}
.table-import.table-bordered,
.table-import.table-bordered>tbody>tr>td,
.table-import.table-bordered>tbody>tr>th,
.table-import.table-bordered>tfoot>tr>td,
.table-import.table-bordered>tfoot>tr>th,
.table-import.table-bordered>thead>tr>td,
.table-import.table-bordered>thead>tr>th{
	border: none;
}
.table-import.table-bordered tr{
	font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    height: auto;
}
.table-import.table-bordered tr:nth-child(even) {
    background: #f0f0f0;
	color: rgba(0, 0, 0, 0.87);
}
.table-import.table-bordered th{
	background: #404F5D;
	color: #fff;
	padding: 16px;
}
.table-import.table-bordered>tbody>tr>td{
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	padding: 16px;
}
.table-import td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table-import td.ok {
	background: #5fbb46;
}
.table-import td.fault {
    background: #f4b836;
}
.table-import td.error {
    background: #f44336;
}
.table-import th {
	position: sticky;
	top: 0;
}
.table-import-wrap {
	position: relative;
	height: 450px;
	overflow-y: scroll;
	overflow-x: scroll;
	border: 1px solid #ddd;
}
.table-import-wrap.nocontent {
	overflow-x: auto;
}
.table-import-wrap::-webkit-scrollbar {
	width: 0;
	height: 0;
}
.table-import-wrap:hover::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}
.table-import-wrap::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.table-import-wrap::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 2px;
}
.table-import-wrap::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.table-import-wrap::-webkit-scrollbar-corner {
	background: #f1f1f1;
}
.MuiTableHead-root, .MuiTableHead-root th{
	z-index: 88;
}
@media (max-width: 1650px) and (min-width: 1451px){
	.col-issue-left{
		width: 60%;
	}
	.col-issue-right{
		width: 40%;
	}
}
@media (max-width: 1450px) and (min-width: 1200px){
	.col-issue-left{
		width: 67%;
	}
	.col-issue-right{
		width: 33%;
	}
}
@media (max-width: 1440px) and (min-width: 1200px) {
	.media-files .preview-img a.delete img,
	.media-files .preview-img a.medium img{
		width: 18px;
	}
	.media-files .preview-img a.delete{
		top: 2px;
		right: 9px;
	}
	.media-files .preview-img a.medium{
		bottom: 17px;
		right: 9px;
	}
}
@media (max-width: 1340px) and (min-width: 1200px) {
	.tabs li a{
		font-size: 14px;
		padding: 0 10px;
	}
}
@media (max-width: 1024px){
	.media-files .preview-img a.delete,
	.media-files .preview-img a.medium,
	.issue-media-preview .preview-item .delete {
		display: block;
	}
}
@media (max-width: 991px){
	.issue-item .issue-img.proc{
		padding: 0;
	}
	.media-files .preview-img a.delete img,
	.media-files .preview-img a.medium img{
		width: 18px;
	}
	.media-files .preview-img a.delete{
		top: 2px;
		right: 9px;
	}
	.media-files .preview-img a.medium{
		bottom: 17px;
		right: 9px;
	}
}
@media (max-width: 768px) {
	.tabs li a img{
		float: none;
		display: block;
		margin: 0 auto;
	}
	.tabs li a {
		line-height: 30px;
		font-size: 10px;
		padding: 0;
		text-align: center;
		padding-top: 10px;
	}
	.media-files .preview-img a.delete img,
	.media-files .preview-img a.medium img{
		width: 20px;
	}
}
@media (max-width: 720px) {
	.sidebar ul.links{
		display: none;
	}
	.sidebar-collapsed .sidebar ul.links{
		display: block;
	}
}
@media (max-width: 500px) {
	.MuiTablePagination-caption{
		display: none;
	}
	.MuiPopover-root label + .MuiInput-formControl{
		margin-top: 27px;
	}
	.MuiPopover-root .MuiPopover-paper{
		min-width: 90%;
    	left: 5% !important;
	}
	.create-issue-container .card-body .btn{
		/* padding: 5px 15px; */
		padding: 5px 10px;
	}
}
.transition-actions {
	padding: 0 0 30px 0;
	margin: 0 -15px;
}
.transition-actions .action-wrap {
	float: right;
	width: 50%;
	padding: 0 15px;
}
.transition-actions .action-wrap .btn {
	width: 100%;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
}
.transition-actions .action-wrap .info,
.transition-actions .action-wrap .info:active {
	background: #08A9F4;
	border-color: #08A9F4;
	color: #fff;
}
.transition-actions .action-wrap .success,
.transition-actions .action-wrap .success:active {
	background: #5fbb46;
	border-color: #5fbb46;
	color: #fff;
}
.transition-actions .action-wrap .danger,
.transition-actions .action-wrap .danger:active {
	background: #f44336;
	border-color: #f44336;
	color: #fff;
}
.transition-actions .action-wrap .warning,
.transition-actions .action-wrap .warning:active {
	background: #FF9700;
	border-color: #FF9700;
	color: #fff;
}
.transition-actions .action-wrap .grey,
.transition-actions .action-wrap .grey:active {
	background: #999;
	border-color: #999;
	color: #fff;
}