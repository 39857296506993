/*!

=========================================================
* Material Dashboard PRO React - v1.8.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// variables and mixins
@import "material-dashboard/variables";
@import "material-dashboard/mixins";
@import "material-dashboard/shadows";

// plugin css
@import "material-dashboard/plugins/plugin-nouislider";
@import "material-dashboard/plugins/plugin-perfect-scrollbar";
@import "material-dashboard/plugins/plugin-react-datetime";
@import "material-dashboard/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard/plugins/plugin-react-chartist";
@import "material-dashboard/plugins/plugin-react-big-calendar";
@import "material-dashboard/plugins/plugin-react-jvectormap";
@import "material-dashboard/plugins/plugin-react-table";
@import "material-dashboard/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard/misc";
@import "material-dashboard/fileupload";
@import "material-dashboard/fixed-plugin";
