.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 2px solid transparent;
	padding: 0.46875rem 2rem;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 4px;
	color: #ffffff;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover,
.btn:focus {
	text-decoration: none;
}
.btn:focus,
.btn.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}
.btn.disabled,
.btn:disabled {
	opacity: 0.65;
	box-shadow: none;
	background-color: #f5f5f5;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
	background-image: none;
	box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
	box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25), none;
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-primary {
	color: #404f5d;
	border-color: #404f5d;
	box-shadow: none;
}
.btn-primary:hover {
	background-color: #404f5d;
	border-color: #404f5d;
}
.btn-primary:focus,
.btn-primary.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}
.btn-primary.disabled,
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
	color: #404f5d;
	background-color: #f5f5f5;
	border-color: #404f5d;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	color: #404f5d;
	background-color: #ffffff;
	border-color: #404f5d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}
.btn-secondary {
	color: #bdbdbd;
	border-color: #bdbdbd;
	box-shadow: none;
}
.btn-secondary.solid,
.btn-secondary:hover {
	color: #ffffff;
	background-color: #bdbdbd;
	border-color: #bdbdbd;
}
.btn-secondary:focus,
.btn-secondary.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
	color: #6c757d;
	background-color: #f5f5f5;
	border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
	color: #bdbdbd;
	background-color: #ffffff;
	border-color: #bdbdbd;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
	color: #5ebb46;
	border-color: #5ebb46;
	box-shadow: none;
}
.btn-success.solid,
.btn-success:hover {
	color: #ffffff;
	background-color: #5ebb46;
	border-color: #5ebb46;
}
.btn-success:focus,
.btn-success.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(85, 185, 89, 0.5);
}
.btn-success.disabled,
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success:disabled:hover {
	color: #5ebb46;
	background-color: #f5f5f5;
	border-color: #5ebb46;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
	color: #5ebb46;
	background-color: #f5f5f5;
	border-color: #5ebb46;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(85, 185, 89, 0.5);
}

.btn-success.solid:not(:disabled):not(.disabled):active,
.btn-success.solid:not(:disabled):not(.disabled).active,
.show>.btn-success.solid.dropdown-toggle {
	color: #ffffff;
	background-color: #5ebb46;
	border-color: #5ebb46;
}
.btn-info {
	color: #ffffff;
	background-color: #00bcd4;
	border-color: #00bcd4;
	box-shadow: none;
}
.btn-info.solid,
.btn-info:hover {
	color: #ffffff;
	background-color: #009aae;
	border-color: #008fa1;
}
.btn-info:focus,
.btn-info.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
	color: #ffffff;
	background-color: #f5f5f5;
	border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
	color: #ffffff;
	background-color: #008fa1;
	border-color: #008394;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-warning {
	color: #212529;
	background-color: #ffeb3b;
	border-color: #ffeb3b;
	box-shadow: none;
}
.btn-warning.solid,
.btn-warning:hover {
	color: #212529;
	background-color: #ffe715;
	border-color: #ffe608;
}
.btn-warning:focus,
.btn-warning.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(255, 235, 59, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
	color: #212529;
	background-color: #ffeb3b;
	border-color: #ffeb3b;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffe608;
	border-color: #fae100;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(255, 235, 59, 0.5);
}
.btn-danger {
	color: #f44336;
	border-color: #f44336;
	box-shadow: none;
}
.btn-danger.solid,
.btn-danger:hover {
	color: #ffffff;
	background-color: #f44336;
	border-color: #f44336;
}
.btn-danger:focus,
.btn-danger.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger:disabled:hover {
	color: #f44336;
	background-color: #f5f5f5;
	border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
	color: #ffffff;
	background-color: #f44336;
	border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
	box-shadow: none;
}
.btn-light.solid,
.btn-light:hover {
	color: #212529;
	background-color: #e2e6ea;
	border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
	color: #212529;
	background-color: #dae0e5;
	border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
	color: #ffffff;
	background-color: #343a40;
	border-color: #343a40;
	box-shadow: none;
}
.btn-dark.solid,
.btn-dark:hover {
	color: #ffffff;
	background-color: #23272b;
	border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
	box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
	color: #ffffff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
	color: #ffffff;
	background-color: #1d2124;
	border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
	color: #2196f3;
	background-color: transparent;
	background-image: none;
	border-color: #2196f3;
}
.btn-outline-primary.solid,
.btn-outline-primary:hover {
	color: #ffffff;
	background-color: #2196f3;
	border-color: #2196f3;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #2196f3;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
	color: #ffffff;
	background-color: #2196f3;
	border-color: #2196f3;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}
.btn-outline-secondary {
	color: #6c757d;
	background-color: transparent;
	background-image: none;
	border-color: #6c757d;
}
.btn-outline-secondary.solid,
.btn-outline-secondary:hover {
	color: #ffffff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #6c757d;
	background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
	color: #ffffff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
	color: #5ebb46;
	background-color: transparent;
	background-image: none;
	border-color: #5ebb46;
}
.btn-outline-success.solid,
.btn-outline-success:hover {
	color: #ffffff;
	background-color: #5ebb46;
	border-color: #5ebb46;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #5ebb46;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
	color: #ffffff;
	background-color: #5ebb46;
	border-color: #5ebb46;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info {
	color: #00bcd4;
	background-color: transparent;
	background-image: none;
	border-color: #00bcd4;
}
.btn-outline-info.solid,
.btn-outline-info:hover {
	color: #ffffff;
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #00bcd4;
	background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
	color: #ffffff;
	background-color: #00bcd4;
	border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
	color: #ffeb3b;
	background-color: transparent;
	background-image: none;
	border-color: #ffeb3b;
}
.btn-outline-warning.solid,
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffeb3b;
	border-color: #ffeb3b;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #ffeb3b;
	background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffeb3b;
	border-color: #ffeb3b;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5);
}
.btn-outline-danger {
	color: #f44336;
	background-color: transparent;
	background-image: none;
	border-color: #f44336;
}
.btn-outline-danger.solid,
.btn-outline-danger:hover {
	color: #ffffff;
	background-color: #f44336;
	border-color: #f44336;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #f44336;
	background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
	color: #ffffff;
	background-color: #f44336;
	border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;
}
.btn-outline-light.solid,
.btn-outline-light:hover {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
	color: #343a40;
	background-color: transparent;
	background-image: none;
	border-color: #343a40;
}
.btn-outline-dark.solid,
.btn-outline-dark:hover {
	color: #ffffff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #343a40;
	background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
	color: #ffffff;
	background-color: #343a40;
	border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
	font-weight: 400;
	color: #9c27b0;
	background-color: transparent;
}
.btn-link.solid,
.btn-link:hover {
	color: #0a6ebd;
	text-decoration: underline;
	background-color: transparent;
	border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
	text-decoration: underline;
	border-color: transparent;
	box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
	color: #999999;
}
.btn-lg,
.btn-group-lg>.btn {
	padding: 1.125rem 2.25rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm>.btn {
	padding: 0.40625rem 1.25rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.1875rem;
}
.btn-block {
	display: block;
	width: 100%;
}
.text-danger{
	color: #f44336;
}
.MuiFormControl-root > div:after {
    border-bottom-color: #5EBB46 !important;
}
.MuiFormControl-root > div.Mui-error:after {
    border-bottom-color: #f44336 !important;
}
.MuiInputBase-root {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1.5;
}
.fullwidth-select .MuiInputBase-root {
	width: 100%;
}
.MuiInputLabel-formControl.MuiInputLabel-shrink {
	top: -5px;
	font-size: 18px;
}
.MuiFormLabel-root {
    font-family: 'Montserrat', sans-serif;
	font-size: 16px;
    line-height: 24px;
}
.MuiFormLabel-root.Mui-focused {
    color: #000;
}
.MuiInputBase-input{
	height: 1.57em;
}
.MuiSelect-select:focus{
	background: transparent;
}
.MuiMenuItem-root {
    font-family: 'Montserrat', sans-serif;
	font-size: 16px;
}
.MuiTablePagination-selectIcon {
    top: 5px;
}
.MuiTableHead-root,
.MuiTableHead-root th {
	 z-index: 90;
}
.MuiTableHead-root th {
	 background: #404F5D;
	 color: #fff;
}
.MuiTableHead-root th div,
.MuiTableHead-root th span,
.MuiTableHead-root th a {
	 color: #fff;
}
.MuiTableSortLabel-icon {
	fill: #fff;
}
.MuiTableSortLabel-root {
	height: 20px;
}
.MuiTableCell-root {
    font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	height: auto;
}
.MuiTableBody-root tr:nth-child(even) {
	background: #f0f0f0;
}
.MuiTableCell-root div,
.MuiTableCell-root span,
.MuiTableCell-root p,
.MuiTableCell-root a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
.MuiToolbar-root {
    padding: 0;
}
.MuiDialogTitle-root {
	padding: 15px;
}
.MuiDialogTitle-root h2 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 0;
}
.MuiPaper-root > div {
    margin-left: 0;
    margin-right: 0;
}
.MuiPaper-root .MuiChip-root {
	margin-bottom: 10px;
}
.MuiFormHelperText-root {
    font-family: 'Montserrat', sans-serif;
    font-size: 85%;
}
.MuiDialog-root {
	position: absolute !important;
	z-index: 90 !important;
}
.MuiDialog-container {
	height: auto;
	opacity: 1;
}
.MuiDialog-paperWidthSm {
	max-width: none;
}
.MuiDialogContent-root {
	padding: 15px !important;
}
.album .MuiDialogContent-root {
	padding: 5px !important;
}
.MuiBackdrop-root {
	background-color: #000;
	opacity: .7 !important;
}
.card .MuiPaper-root {
	box-shadow: none;
}

@media (max-width: 959.95px){
	.MuiTableCell-root {
		font-size: 12px;
		height: auto;
		padding: 5px;
	}
	.MuiTableCell-root div,
	.MuiTableCell-root span,
	.MuiTableCell-root p,
	.MuiTableCell-root a {
		font-size: 12px;
	}
	.MuiTableBody-root tr td:nth-child(odd) {
		width: 30%;
	}
	.MuiTableBody-root tr td:nth-child(even) {
		width: 70%;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
  -webkit-text-fill-color: inherit;
}